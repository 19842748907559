import React from 'react';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const AboutMePage: React.FC = () => (
  <Layout>
    <SEO title={'About Me'} />
    <main className="container">
      <h1 className="jumbo">Where Ideas Come To Play</h1>
      <h2 className="subheading">Senior Art Director</h2>
      <p>
        <b>
          Concepting bold ideas. Delivering them with beautiful execution.
          That’s what has driven me throughout my creative career. For over 8
          years I’ve been creating, leading and delivering breakthrough
          marketing for a wide variety of major global brands and platforms
          including TV, video, digital, social, web &amp; print.
        </b>
      </p>
      <span className="marque">Snapshot</span>
      <div className="three-col-grid">
        <div>
          <h3 className="with-underline">Creator &amp; Collaborator</h3>
          <p>
            Creating big campaign ideas isn’t just a career for me, it’s in my
            DNA – what gets me out of bed every morning. Whether its
            collaborating and concepting as a team or going solo, I bring my
            passion, experience and creative mind to every brief – from first
            scamps to final delivery.
          </p>
        </div>
        <div>
          <h3 className="with-underline">Leader &amp; Mentor</h3>
          <p>
            I take great pride in developing and managing the creatives I have
            mentored, making their personal development my priority. I direct,
            motivate and inspire to get the best work out of everyone. I love
            getting involved from the beginning of any brief and work with the
            strategy and client services teams to help shape and form the brief
            from the beginning.
          </p>
        </div>
        <div>
          <h3 className="with-underline">Advocate &amp; Asset</h3>
          <p>
            The drive for great creative doesn’t stop at the brief. On multiple
            occasions I’ve been the voice of the agency outside work, whether
            that’s being a judge for <b>The Campaign Big Awards</b>, running
            student workshops at universities in Manchester, Leeds and
            Huddersfield, or participating in portfolio sessions with the DMA
            across the North West.
          </p>
        </div>
      </div>
    </main>
  </Layout>
);

export default AboutMePage;
