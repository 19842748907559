import { Link } from 'gatsby';
import React from 'react';
import {
  ContentfulWorkItem,
  GatsbyContentfulFluidFragment,
  Maybe,
} from '../../../graphql-types';
import Img, { FluidObject } from 'gatsby-image';

interface GridProps {
  items: (Pick<ContentfulWorkItem, 'client' | 'id' | 'url' | 'title'> & {
    thumbnail?:
      | Maybe<{
          fluid?: Maybe<GatsbyContentfulFluidFragment> | undefined;
        }>
      | undefined;
  })[];
  masonry?: boolean;
  recentWork?: boolean;
}

export const Grid = ({
  items,
  masonry = false,
  recentWork = false,
}: GridProps): JSX.Element => {
  const gridItems = items;
  return (
    <div
      className={`grid ${masonry && 'grid--masonry'} ${
        recentWork && 'grid--recent'
      }`}
    >
      {gridItems.map((item, index) => {
        const image = item.thumbnail?.fluid;
        return (
          <div key={`${item.id}__${index}`} className="grid__item">
            <Link to={`/work/${item.url}`} className="work-panel">
              <div className="work-panel__gradient" />
              <div className="work-panel__image">
                <Img
                  fluid={image as FluidObject}
                  alt={`${item.client}, ${item.title}`}
                  style={{
                    height: '100%',
                  }}
                />
              </div>
              <span className="work-panel__client">{item.client}</span>
              <span className="work-panel__title">{item.title}</span>
            </Link>
          </div>
        );
      })}
      {recentWork && (
        <div className="grid__item">
          <Link
            to={`/work/`}
            className="work-panel work-panel--no-hover bg-blue"
          >
            <div className="work-panel__gradient" />
            <span className="work-panel__client">
              View All{' '}
              <svg
                width="30"
                height="20"
                viewBox="0 0 30 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.125 10C0.125 9.71821 0.236942 9.44796 0.436199 9.2487C0.635456 9.04944 0.905707 8.9375 1.1875 8.9375H26.2476L19.5602 2.25225C19.3607 2.05274 19.2487 1.78215 19.2487 1.5C19.2487 1.21785 19.3607 0.947258 19.5602 0.747749C19.7598 0.54824 20.0304 0.436157 20.3125 0.436157C20.5946 0.436157 20.8652 0.54824 21.0648 0.747749L29.5648 9.24775C29.6637 9.34645 29.7422 9.46369 29.7958 9.59278C29.8493 9.72186 29.8769 9.86024 29.8769 10C29.8769 10.1398 29.8493 10.2781 29.7958 10.4072C29.7422 10.5363 29.6637 10.6536 29.5648 10.7522L21.0648 19.2522C20.8652 19.4518 20.5946 19.5638 20.3125 19.5638C20.0304 19.5638 19.7598 19.4518 19.5602 19.2522C19.3607 19.0527 19.2487 18.7821 19.2487 18.5C19.2487 18.2179 19.3607 17.9473 19.5602 17.7477L26.2476 11.0625H1.1875C0.905707 11.0625 0.635456 10.9506 0.436199 10.7513C0.236942 10.552 0.125 10.2818 0.125 10V10Z"
                  fill="#FCF6F5"
                />
              </svg>
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};
