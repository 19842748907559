import React from 'react';
import { Grid } from '../components/grid';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';
import type { WorkPageQuery } from '../../graphql-types';

type WorkPageProps = {
  data: WorkPageQuery;
};

const WorkPage = ({ data }: WorkPageProps): JSX.Element => {
  const work = data.allContentfulWorkGrid.nodes[0].work;
  return (
    <Layout>
      <SEO title={'Work'} />
      <main className="container">
        <section id="work">
          <h1 className="jumbo">Work</h1>
          <Grid items={work} masonry={true} />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query WorkPage {
    allContentfulWorkGrid {
      nodes {
        work {
          client
          id
          url
          title
          thumbnail {
            fluid(maxWidth: 888) {
              ...GatsbyContentfulFluid_tracedSvg_withWebp
            }
          }
        }
      }
    }
  }
`;

export default WorkPage;
