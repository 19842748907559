import { Link } from 'gatsby';
import React from 'react';
import { Banner } from '../components/banner';
import { Grid } from '../components/grid';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { graphql } from 'gatsby';
import type { IndexPageQuery } from '../../graphql-types';

type IndexPageProps = {
  data: IndexPageQuery;
};

const IndexPage = ({ data }: IndexPageProps): JSX.Element => {
  const work = data.allContentfulWorkItem.nodes;

  return (
    <Layout>
      <SEO title={'Home'} />
      <Banner />
      <main className="container">
        <section>
          <p className="attention-grabber">
            <b>
              Concepting bold ideas. Delivering them with beautiful execution.
              That’s what has driven me throughout my creative career. For over
              8 years I’ve been creating, leading and delivering breakthrough
              marketing for a wide variety of major global brands and platforms
              including TV, video, digital, social, web &amp; print.
            </b>
          </p>
          <Link
            to={'/about-me/'}
            className="cta-link with-underline with-underline--inline"
          >
            <span className="visually-hidden">Click here to</span> Read more{' '}
            <span className="visually-hidden">about Adam Woolley</span>
          </Link>
        </section>
        <section id="work">
          <h1 className="notSoJumbo">Recent Work</h1>
          <Grid items={work} masonry={true} recentWork={true} />
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    allContentfulWorkItem(limit: 2, sort: { fields: createdAt, order: DESC }) {
      nodes {
        client
        id
        url
        title
        thumbnail {
          fluid(maxWidth: 888) {
            ...GatsbyContentfulFluid_tracedSvg_withWebp
          }
        }
      }
    }
  }
`;

export default IndexPage;
