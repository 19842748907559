import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main className="container">
      <h1 className="jumbo">Page not found</h1>
    </main>
  </Layout>
);

export default NotFoundPage;
