import * as React from 'react';
import { motion, SVGMotionProps } from 'framer-motion';

const Path = (props: SVGMotionProps<SVGPathElement>): JSX.Element => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="#EA4FE7"
    strokeLinecap="round"
    {...props}
  />
);

interface NavigationToggleProps {
  onPress: () => void;
}

export const NavigationToggle = ({
  onPress,
}: NavigationToggleProps): JSX.Element => (
  <button
    aria-label="Toggle Mobile Navigation"
    onClick={onPress}
    className="navigation-toggle"
  >
    <svg width="21" height="18" viewBox="0 0 21 18">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { stroke: '#FFFFFF', d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { stroke: '#FFFFFF', d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>
);
