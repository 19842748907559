import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import { useWindowSize } from '../../hooks';

export const MobileNavigation = (): JSX.Element => {
  const size = useWindowSize();

  return (
    <motion.nav
      className="mobile-navigation"
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      style={{
        height: size.height,
      }}
    >
      <motion.div className="mobile-navigation__inner">
        <Link
          activeClassName="mobile-navigation__link--active"
          className="mobile-navigation__link"
          to={'/work/'}
        >
          <span>Work</span>
        </Link>
        <Link
          activeClassName="mobile-navigation__link--active"
          className="mobile-navigation__link"
          to={'/about-me/'}
        >
          <span>About Me</span>
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          aria-label="View Adam Woolley on LinkedIn"
          href="https://www.linkedin.com/in/adam-woolley-419b9949/"
        >
          <svg
            preserveAspectRatio="xMinYMin meet"
            id="Group_301"
            data-name="Group 301"
            xmlns="http://www.w3.org/2000/svg"
            width="44.229"
            height="41.061"
            viewBox="0 0 44.229 41.061"
          >
            <path
              id="Path_11"
              data-name="Path 11"
              d="M985.055,2097.533v34.005a.491.491,0,0,0-.073.137,4.378,4.378,0,0,1-4.488,3.388q-17.555.005-35.109,0a5.379,5.379,0,0,1-1.194-.126,4.189,4.189,0,0,1-3.363-4.14q0-16.256,0-32.515c0-.12,0-.24.006-.361a4.005,4.005,0,0,1,1.5-2.873,5.481,5.481,0,0,1,2.289-1.04h36.626a1.269,1.269,0,0,0,.19.073,4.559,4.559,0,0,1,2.6,1.455A4.95,4.95,0,0,1,985.055,2097.533Zm-6.658,31.353c.006-.12.016-.2.016-.278,0-3.795.018-7.591-.008-11.386a6.828,6.828,0,0,0-3.385-5.91,7.113,7.113,0,0,0-7.914-.177,22.276,22.276,0,0,0-1.943,1.551v-2.226h-6.6v18.419h6.6v-.493q0-4.97,0-9.942c0-.267.008-.535.034-.8a3.329,3.329,0,0,1,4.154-2.56,3.164,3.164,0,0,1,2.431,3.179q.009,5.071,0,10.143v.481Zm-24.329,0v-18.421H947.5v18.421Zm.678-25.635a3.986,3.986,0,1,0-3.986,3.676A3.847,3.847,0,0,0,954.746,2103.251Z"
              transform="translate(-940.826 -2094.006)"
              fill="#FFFFFF"
            />
          </svg>
        </a>
      </motion.div>
    </motion.nav>
  );
};
