import React, { useEffect } from 'react';
import { motion, useCycle, AnimatePresence } from 'framer-motion';
import { Logo } from '../logo';
import { NavigationToggle } from '../navigation-toggle/';
import { MobileNavigation } from '../mobile-navigation';
import { Navigation } from '../navigation';
import { Link } from 'gatsby';

const scrollLock = 'scroll-lock';

export const Header = (): JSX.Element => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  useEffect(() => {
    const body = document.querySelector('body');
    if (isOpen) {
      body?.classList.add(scrollLock);
    } else {
      body?.classList.remove(scrollLock);
    }
  }, [isOpen]);
  return (
    <motion.header className="header" animate={isOpen ? 'open' : 'closed'}>
      <div className="header__inner">
        <Link to={'/'} aria-label={'Home'}>
          <Logo />
        </Link>
        <NavigationToggle onPress={toggleOpen} />
        <Navigation />
      </div>
      <AnimatePresence>{isOpen && <MobileNavigation />}</AnimatePresence>
    </motion.header>
  );
};
