import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';

interface WorkByURLProps {
  data: any;
}

const layoutTypes = {
  'Full Width': 'custom-layout--full-width',
  '2x2': 'custom-layout--2x2',
  '3x3': 'custom-layout--3x3',
  '3x3 Reversed': 'custom-layout--3x3',
  '4x4': 'custom-layout--4x4',
};

const WorkItemTemplate = ({ data, pageContext }: any): JSX.Element => {
  const nextItem = pageContext.next;
  const {
    client,
    title,
    overview,
    keyPoints,
    hero,
    videoPoster,
    description,
    content,
    thumbnail,
  } = data.contentfulWorkItem;
  const ogImage = `https://${thumbnail.fixed.src.replace(
    '//images',
    'images'
  )}`;
  const isVideo = hero.file.contentType.startsWith('video');

  return (
    <Layout>
      <SEO
        title={`${client} | ${title}`}
        ogImage={ogImage}
        description={overview.overview}
      />
      <main className="container">
        <h1 className="work-item__client text-blue">{client}</h1>
        <h2 className="work-item__title">{title}</h2>
        <p className="work-item__overview">
          <b>{overview.overview}</b>
        </p>
        <div className="work-item__hero work-item__hero--mobile">
          {isVideo ? (
            <video
              poster={videoPoster.fixed.src}
              autoPlay={false}
              controls={true}
              src={hero.file.url}
            />
          ) : (
            <Img
              fluid={hero.fluid}
              alt={`${client}, ${title}`}
              style={{
                height: '100%',
              }}
            />
          )}
        </div>
        <div className="work-item__meta">
          <div>
            <h3 className="with-underline with-underline--inline">
              How I Made It Happen
            </h3>
            {documentToReactComponents(JSON.parse(description.raw))}
          </div>
          <div>
            <h3 className="with-underline with-underline--inline">Snapshot</h3>
            {keyPoints && (
              <ul>
                {keyPoints.map((point: string) => (
                  <li key={point}>{point}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="work-item__hero work-item__hero--desktop">
          {isVideo ? (
            <video
              poster={videoPoster.fixed.src}
              autoPlay={false}
              controls={true}
              src={hero.file.url}
            />
          ) : (
            <Img
              fluid={hero.fluid}
              alt={`${client}, ${title}`}
              style={{
                height: '100%',
              }}
            />
          )}
        </div>
        {content?.map((content: any) => {
          return (
            <div
              key={content.id}
              className={`custom-layout ${layoutTypes[content.layoutType]}`}
            >
              <div className="content-grid">
                {content.media.map((item: any, index: number) => {
                  const isVideo = item.file.contentType.startsWith('video');
                  return (
                    <div key={index}>
                      {isVideo ? (
                        <video
                          autoPlay={false}
                          controls={true}
                          src={item.file.url}
                        />
                      ) : (
                        <Img
                          fluid={item.fluid}
                          alt={`${client}, ${title}`}
                          style={{
                            height: '100%',
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              {content?.descripton &&
                documentToReactComponents(JSON.parse(content.description.raw))}
            </div>
          );
        })}
        <section className="work-item__pagination">
          <Link
            className="work-item__pagination-link work-item__pagination-link--all-work"
            to={'/work/'}
          >
            <svg
              width="15"
              height="26"
              viewBox="0 0 15 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2501 1.66669L1.91675 13L13.2501 24.3334"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            All Work
          </Link>
          {nextItem && (
            <Link
              className="work-item__pagination-link work-item__pagination-link--next"
              to={`/work/${nextItem.url}/`}
            >
              Next
              <svg
                width="15"
                height="26"
                viewBox="0 0 15 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2501 1.66669L1.91675 13L13.2501 24.3334"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          )}
        </section>
      </main>
    </Layout>
  );
};

export default WorkItemTemplate;

export const query = graphql`
  query WorkByURL($url: String!) {
    contentfulWorkItem(url: { eq: $url }) {
      client
      title
      thumbnail {
        fixed(width: 1200, height: 600) {
          ...GatsbyContentfulFixed
        }
      }
      overview {
        overview
      }
      description {
        raw
      }
      keyPoints
      hero {
        file {
          url
          contentType
        }
        fluid(maxWidth: 1320) {
          ...GatsbyContentfulFluid_tracedSvg_withWebp
        }
      }
      videoPoster {
        fixed(width: 1320) {
          ...GatsbyContentfulFixed
        }
      }
      content {
        ... on ContentfulContentBlock {
          id
          layoutType
          description {
            raw
          }
          media {
            id
            file {
              url
              contentType
            }
            fluid(maxWidth: 1320) {
              ...GatsbyContentfulFluid_tracedSvg_withWebp
            }
          }
        }
      }
    }
  }
`;
