import React from 'react';

export const Footer = (): JSX.Element => {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__col">
          <span className="footer__cta">Get In Touch</span>
          <a className="footer__text" href="mailto:adamjwoolley@gmail.com">
            <b>adamjwoolley@gmail.com</b>
          </a>
        </div>
        <div className="footer__col">
          <span className="footer__text footer__text--align-right footer__text--light">
            <b>
              All content ©{new Date().getFullYear()} Adam Woolley.
              <br />
              All rights reserved.
            </b>
          </span>
        </div>
      </div>
    </footer>
  );
};
