import React from 'react';
import logo from '../../images/logo-large.svg';

const videoUrl =
  'https://videos.ctfassets.net/suaa0o9cpkbb/4hPPm2OtcP2KG21iNITMzB/eab8c0c2acab1a236a630005b4bda9dc/banner-video.mp4';

export const Banner = (): JSX.Element => {
  return (
    <div className="banner">
      <div className="banner__mask" />
      <div className="banner__inner">
        <img src={logo} alt="" />
      </div>
      <video
        src={videoUrl}
        className="banner__video"
        autoPlay={true}
        loop={true}
        playsInline={true}
        muted={true}
      />
    </div>
  );
};
