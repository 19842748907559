import React from 'react';
import { Helmet } from 'react-helmet';
import 'normalize.css';
import '../styles/style.scss';
import { Header } from './header';
import { Footer } from './footer';
interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,300;0,900;1,300;1,900&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
};
